"use client";
import React from "react";
import "./SuccessFailure.css";

const SuccessPage = () => {
  return (
    <div className="success-page">
      <div className="content">
        <h1>Success!</h1>
        <p>Payment done successfully.</p>
        <p>Please Wait ...</p>
      </div>
    </div>
  );
};

export default SuccessPage;
