import React from "react";
import "./SuccessFailure.css";

const FailurePage = () => {
  return (
    <div className="failure-page">
      <div className="content">
        <h1>Oops!</h1>
        <p>Something went wrong. Please try again.</p>
        <p>Please Wait ...</p>
      </div>
    </div>
  );
};

export default FailurePage;
